<template>
  <div class="body-content" data-app>
    <div class="row">
      <div class="col-xl-12 col-md-12 col-sm-12 mb-3 ddd">
        <!-- <span>
          <button
            style="font-size: 1.3rem; margin-top: 0px"
            type="button"
            class="fa fa-redo last_refreshed mx-2"
            @click="refreshed_data"
          ></button>
        </span> -->
        <span class="last_seen">
          <!-- <span> Last Refreshed : {{ prettyTime }} ago</span> -->

          <!-- <span class="last_refreshed">Refresh Page </span> -->
        </span>
      </div>
      <div class="col-xl-12">
        <div class="inf-table">
          <div class="row">
            <div class="col">
              <h4 class="py-6">ADVERTISER ORDERS - LAST UPDATED DATE</h4>
            </div>
            <div class="col">
              <v-text-field
                v-model="search"
                @keyup="onkeyupofsearch()"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
          <Loader
            v-if="is_loading"
            style="display: flex; align-items: center; justify-content: center"
            v-bind:logo="loaderLogo"
          ></Loader>
          <div
            style="
              height: 500px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            v-if="lodingCir"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div
            v-else
           class="mobile-modal"
            @scroll="onScroll"
          >
            <v-data-table
              style="box-shadow: 0 4px 6px -6px #222"
              class="font-size-increase table-boardered"
              fixed-header
              :headers="columns"
              :items="rows"
              :items-per-page="itemsPerPage"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </div>
          <div
            style="
              position: absolute;
              bottom:0;
              left: 0;
              text-align:center;
              width:100%;
              z-index-10;
            "
            v-if="lodingscroll"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <!-- <div class="row mt-6">
            <div
              class="col"
              style="
                justify-content: flex-start;
                display: flex;
                flex-direction: column;
              "
            >
              <div>
                <v-pagination
                  style="float: left"
                  color="#151A4B"
                  v-model="couponPagePagination"
                  @input="fetchData"
                  :length="totalPage"
                  circle
                ></v-pagination>
              </div>
              <div class="p-3">
                Page Size:
                <select
                  style="width: 50px; margin-left: 10px"
                  v-model="itemsPerPage"
                  v-on:change="fetchData"
                >
                  <option :value="10">10</option>
                  <option :value="25">25</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                </select>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import Loader from "@/view/content/Loader.vue";
// import 'vue2-datepicker/index.css';
import Multiselect from "vue-multiselect";
import Button from "./vue-bootstrap/Button.vue";
export default {
  name: "dashboard",
  components: { Multiselect, DatePicker, ApiService, Loader, Button },
  data() {
    return {
      search: "",
      lodingCir: false,
      total_advertiser: "",
      itemsPerPage: 10,
      couponPagePagination: 1,
      is_loading: false,
      rows: [],
      columns: [
        {
          text: "ADVERTISER",
          value: "advertiser",
          align: "center",
          sortable: false,
        },
        {
          text: "LAST UPDATED DATE",
          value: "last_updated_date",
          align: "center",
          sortable: false,
        },
      ],
      is_loading: false,
      last_refreshed: localStorage.getItem("lastSeen")
        ? new Date(localStorage.getItem("lastSeen"))
        : new Date(),
      advertiserList: [],
      couponList: [],
      coupon_statuses: ["Get All", "Active", "In-Active"],
      timeout: null,
      lodingscroll: false,
    };
  },
  created() {
    this.lodingCir = true;
    setTimeout(() => {
      this.lodingCir = false;
    }, 500);
    this.fetchData();
  },
  methods: {
    onkeyupofsearch() {
      if (this.search.length <= 0) {
        this.rows = [];
        this.couponPagePagination = 1;
        this.lodingCir = true;
        this.fetchData();
        return false;
      }
      this.lodingCir = true;
      this.couponPagePagination = 0;
      this.rows = [];
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.fetchData();
      }, 500);
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.search.length > 0) {
        return false;
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.couponPagePagination > this.totalPage) {
          return false;
        } else {
          this.couponPagePagination += 1;
        }
        this.lodingscroll = true;
        this.fetchData();
        // console.log("hi");
      }
    },
    fetchData() {
      // this.lodingCir = true;
      ApiService.post("/advertiser_list", {
        page: this.couponPagePagination,
        pagesize: 30,
        advertiser: this.search,
      }).then((rowData) => {
        this.lodingCir = false;
        this.lodingscroll = false;
        if (this.couponPagePagination == 0) {
          this.rows = rowData.data.data.advertiser;
        } else {
          this.rows = [...this.rows, ...rowData.data.data.advertiser];
        }
        this.total_advertiser = rowData.data.data.total_advertiser;
      }).catch(error => {
              if(error.response.status == 400){
                localStorage.clear();
                localStorage.removeItem("affiliate_id");
                window.location.reload();
                // Swal.fire({
                //   imageUrl: "media/images/logout-emoji.png",
                //   imageWidth: 165,
                //   imageHeight: 123,
                //   title: "Your Session is expired.",
                //   timer: 1500,
                //   timerProgressBar: true,
                //   showConfirmButton: false,
                // })
                // setTimeout(() => {
                //   window.location.reload();
                // }, 1500);
              }
              throw new Error(`[KT] ApiService ${error}`);
            });
    },
  },
  computed: {
    totalPage() {
      return parseInt(this.total_advertiser / this.itemsPerPage) + 1;
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>

<style lang="scss">

.body-content{
  width: 100%;
}
.font-size-increase,
td {
  font-size: 13px !important;
}
.font-size-increase,
th {
  font-size: 15px !important;
}
.badge {
  color: white;
  border-radius: 5px;
}
.new-tag-bg {
  background: #0253b0;
  border-color: #0253b0;
}
.comfirmed-tag-bg,
.approved-tag-bg {
  background: #00c778;
  border-color: #00c778;
}
.cancelled-tag-bg {
  background: #707070;
  border-color: #707070;
}
.export_file {
  float: right;
  cursor: pointer;
}
.s_date {
  // margin-right: 4%;
  cursor: pointer;
}
.select {
  color: #000;
  font-weight: 700;
}
//   .plan-boxes {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

// @media only screen and (max-width: 600px) {
//   .plan-box {
//     height: 40%;
//     position: relative;
//   }
//   .logo{
//     width: 80% !important;
//   }
// }
.modal-btn {
  width: 100%;
  color: white;
  background: #000;
  border-radius: 0px;
}
.tabs {
  display: flex;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #e8e1e1;
  .tab {
    cursor: pointer;
  }
  .active {
    border-bottom: 1.5px solid black;
  }
}
@media only screen and (max-width: 450px) {
  .tabs {
    display: table !important;

    margin: auto;
  }
}

// for destop
@media only screen and (min-width: 1200px) {
  .plan-boxes {
    span:first-of-type {
      margin-bottom: 7%;
    }
  }
  .plan-box {
    background-color: #ffffff;
    min-height: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }

    h1 {
      font-weight: bold;
      color: #000;
      font-size: 4rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .last_refreshed {
    color: black !important;
    margin-top: 8rem;
    icon {
      color: white;
    }
    span {
      margin-left: 1rem;
    }
  }
}
.ddd {
  margin-top: -2.5rem !important;
}
@media only screen and (max-width: 1200px) {
  .last_refreshed {
    color: white;
    margin-top: 0px !important;
    span {
      margin-left: 1rem;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .last_seen {
    color: white;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

//for screens bigger then mobile
@media only screen and (min-width: 1600px) {
  .last_seen {
    color: black;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

// for mobile
@media only screen and (max-width: 1200px) {
  .plan-boxes {
    margin-bottom: 5%;
    display: inline;
    span:last-of-type {
      float: right;
    }
  }
  .plan-box {
    background-color: #ffffff;
    width: 45%;
    min-height: 100px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
      font-size: 12px;
    }

    h1 {
      font-weight: bold;
      color: #000;
      font-size: 2rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
.light_gray {
  color: rgb(181, 181, 181);
}
.toggle_buttons {
  border: 2px solid rgb(121, 121, 121) !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
  .toggle_buttons {
    width: 6rem !important;
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .toggle_buttons {
    width: 7rem !important;
    height: 4rem !important;
    font-size: 0.85rem !important;
    margin: 0.1rem !important;
  }
}
@media only screen and (max-width: 363px) {
  .toggle_buttons {
    width: 6rem !important;
    height: 2.5rem !important;
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 305px) {
  .toggle_buttons {
    width: 4rem !important;
    height: 3rem !important;
    font-size: 0.5rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .table_res_head {
    display: none !important;
  }
}

#subparent .tab_buttons {
  border: none !important;
  border-radius: 5px !important;
  color: white;
  background-color: #FF6633 !important;
}
#parent .tab_buttons {
  border: none !important;
  border-radius: 5px !important;
  color: #000;
  background-color: #FF6633 !important;
}
.active_status {
  background-color: #00c778;
  padding: 0.2rem 1.8rem;
  border-radius: 0.3rem;
}
.expired_status {
  background-color: #f59253;
  padding: 0.2rem 1.8rem;
  border-radius: 0.3rem;
}
.status_coupon {
  color: rgb(163, 137, 137);
  border-radius: 0.3rem;
}
.mobile-modal{
  max-height: calc(100vh - 320px) !important;
  overflow-y:scroll !important;
}

</style>